<template lang="pug">
div(class='Breadcrumbs')
	template(v-for='({ url, label }, index) in breadcrumbsState', :key='url')
		div(v-if='url === route.path', class='Breadcrumb') {{ label }}
		NuxtLink(v-else, :to='url', class='Breadcrumb') {{ label }}
		div(class='Separator', v-if='index !== breadcrumbsState.length - 1') /
</template>

<script lang="ts" setup>
const route = useRoute();
const { breadcrumbsState } = useBreadcrumbs(route.path);
</script>

<style lang="less" scoped>
.Breadcrumbs {
	.box(flex; auto; auto; none; center; start; center; wrap row);
	& > .Breadcrumb {
		.text(fade(@ColorBase, 50%); 1.25em 0.875rem @semibold; none; none none);
	}
	& > a.Breadcrumb {
		.text(@ColorPrimary);
	}
	& > .Separator {
		padding: 0 0.5rem;
		.text(fade(@ColorBase, 50%); 1.25em 0.875rem @regular; none; none none);
	}

	@media all and (min-width: @tablet) and (max-width: (@semibold - 1px)) {
		& > .Breadcrumb,
		& > .Separator {
			.text(fade(@ColorBase, 50%); 1.25em 1rem @regular; none; none none);
		}
	}
	@media all and (min-width: @desktop) {
		& > .Breadcrumb,
		& > .Separator {
			.text(fade(@ColorBase, 50%); 1.25em 1.125rem @semibold; none; none none);
		}
	}
}
</style>
